// React Basic and Bootstrap
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

//import images
import laredoConstruction from './images/laredo.png';
import laredoOffshore from './images/laredoOffshore.jpg';
import TOPS from './images/TOPS.jpg';
import OTS from './images/OTS.png';
import Redfish from './images/Redfish.png';
import McDonough from './images/McDonough.jpg';
import UE from './images/UE.jpg';
import Bradley from './images/Bradley.jpg';
import Dovetail from './images/Dovetail.png';
import Pennum from './images/Pennum.png';

// import roundWhite from '../../images/shapes/round-white.png';

// Partners
// import Partner from '../../components/Shared/Partner';

class Sponsors extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">Our Sponsors and Teammates</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Who help make <span className="text-primary font-weight-bold">All Jacked Up Cook Off Competition</span> possible.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={laredoConstruction} className="img-fluid d-block mx-auto shadow" alt="Laredo Construction" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="http://laredogroup.org" className="name text-dark">Laredo Construction</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={TOPS} className="img-fluid d-block mx-auto shadow" alt="TOPS" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="http://topsdb1.com/" className="name text-dark">Turnkey Offshore Projects Services (TOPS)</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>        
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={OTS} className="img-fluid d-block mx-auto shadow" alt="Offshore Technical Solutions" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="http://offshoretechnical.com/" className="name text-dark">Offshore Technical Solutions</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={Pennum} className="img-fluid d-block mx-auto shadow" alt="Pennum" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="http://www.pennum.com/" className="name text-dark">Pennum Industries</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={Redfish} className="img-fluid d-block mx-auto shadow" alt="Redfish Rentals" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="http://www.redfishrental.com/" className="name text-dark">Redfish Rentals</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={laredoOffshore} className="img-fluid d-block mx-auto shadow" alt="Laredo Offshore" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="http://laredogroup.org" className="name text-dark">Laredo Offshore</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={McDonough} className="img-fluid d-block mx-auto shadow" alt="McDonough Marine" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="https://www.mcdonoughmarine.com/" className="name text-dark">McDonough Marine</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={Bradley} className="img-fluid d-block mx-auto shadow" alt="Bradley Law" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="https://www.bradley.com/" className="name text-dark">Bradley Law</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                               <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={UE} className="img-fluid d-block mx-auto shadow" alt="Upstream Engineering" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="https://www.upstreamengineering.com/" className="name text-dark">Upstream Engineering</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="team text-center rounded bg-white pt-3">
                                    <div className="position-relative">
                                        <img src={Dovetail} className="img-fluid d-block mx-auto shadow" alt="Dovetail Software" />
                                        <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
                                            {/* <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-google-plus" title="Twitter"></i></Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="content pt-3 pb-3">
                                        <h5 className="mb-0"><a href="https://dovetail.dev/" className="name text-dark">Dovetail Software</a></h5>
                                        <small className="designation text-muted">Sponsor</small>
                                    </div>
                                </div>
                            </Col>             
                        </Row>
                    </div>

                </section>
            </React.Fragment>
        );
    }
}

export default Sponsors;