// React Basic and Bootstrap
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Topbar from './Topbar';


// Import images
import Dovetail from './images/Dovetail.png';

class Photos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDiv: "All"
        }
    }

    render() {

        let pictures = [];
        for (let i = 0; i < 50; i++) {
            pictures.push(Dovetail);
        }

        return (
            <div>
            <Topbar />
            <React.Fragment>
                {/* <section className="section"> */}
                    {/* <div className="container">
                        <Row>
                            <ul className="col container-filter list-unstyled categories-filter text-center" id="filter">
                                <li className="list-inline-item"><Link  to="#" onClick={() => this.setState({ currentDiv: "All" })} className={this.state.currentDiv === "All" ? "categories border d-block text-dark rounded active" : "categories border d-block text-dark rounded" }>All</Link></li>
                                <li className="list-inline-item"><Link to="#" onClick={() => this.setState({ currentDiv: "Branding" })} className={this.state.currentDiv === "Branding" ? "categories border d-block text-dark rounded active" : "categories border d-block text-dark rounded" } >Branding</Link></li>
                                <li className="list-inline-item"><Link to="#" onClick={() => this.setState({ currentDiv: "Designing" })} className={this.state.currentDiv === "Designing" ? "categories border d-block text-dark rounded active" :"categories border d-block text-dark rounded"  }>Designing</Link></li>
                                <li className="list-inline-item"><Link  to="#" onClick={() => this.setState({ currentDiv: "Photography" })} className={this.state.currentDiv === "Photography" ?  "categories border d-block text-dark rounded active" :  "categories border d-block text-dark rounded" } >Photography</Link></li>
                                <li className="list-inline-item"><Link to="#" onClick={() => this.setState({ currentDiv: "Development" })} className={this.state.currentDiv === "Development" ?"categories border d-block text-dark rounded active"  : "categories border d-block text-dark rounded" } >Development</Link></li>
                            </ul>
                        </Row>
                    </div> */}
                    
                    <div className="container-fluid" style={{backgroundColor: "black", marginTop: "40px"}}>
                        <Row className="container-grid projects-wrapper">
                            {this.state.currentDiv === "All" ? pictures.map( (pic, index) => {
                                return (
                                    <Col lg={3} md={6} className="spacing designing">
                                        <div className="work-container position-relative d-block overflow-hidden rounded mt-3">
                                            <a className="mfp-image d-inline-block" target="_blank" href={pic} title="" >
                                                <img src={Dovetail} className="img-fluid rounded" alt="Dovetail" />
                                                <div className="overlay-work"></div>
                                            </a>
                                        </div>
                                    </Col>
                                )
                            })
                                
                            : null}
                            
                         </Row>
                    </div>
                    <div className="container mt-100 mt-60">
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <div className="section-title">
                                    <h4 className="title mb-4">Interested in joining the team or becoming a sponsor?</h4>
                                    <p className="text-muted">See <span className="text-primary font-weight-bold">All Jacked Up</span> contact link below.</p>
                                    <div className="mt-3" style={{marginBottom: "40px"}}>
                                        <Link to="/contact" className="btn btn-danger">Contact Us</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                {/* </section> */}
            </React.Fragment>
            </div>
        );
    }
}

export default Photos;