// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

// Import Generic components

// import images
import event from "./images/AJU.png";
import Dovetail from './images/Dovetail.png';


//CountDown
import Countdown from "react-countdown-now";
import Sponsors from "./Sponsors";
import Topbar from "./Topbar";
import Photos from "./Photos";

// Random component
const Completionist = () => (
  <span>The Rodeo Bar-B-Que Cook Off is Happening! Come on Out!</span>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <React.Fragment>
        <div className="count-down">
          <span className="count-number">{days}</span>
          <span className="count-head position-relative d-block">Days</span>
        </div>
        <div className="count-down">
          <span className="count-number">{hours}</span>
          <span className="count-head position-relative d-block">Hours</span>
        </div>{" "}
        <div className="count-down">
          <span className="count-number">{minutes}</span>
          <span className="count-head position-relative d-block">Minutes</span>
        </div>{" "}
        <div className="count-down">
          <span className="count-number">{seconds}</span>
          <span className="count-head position-relative d-block">Seconds</span>
        </div>
      </React.Fragment>
    );
  }
};

class EventHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   document.body.classList = "";
  //   document.getElementById('topnav').classList.add('bg-white');
  //   window.addEventListener("scroll", this.scrollNavigation, true);
  // }
  // // Make sure to remove the DOM listener when the component is unmounted.
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.scrollNavigation);
  // }

  // scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (top > 80) {
  //     document.getElementById('topnav').classList.add('nav-sticky');
  //   }
  //   else {
  //     document.getElementById('topnav').classList.remove('nav-sticky');
  //   }
  // }

  

  render() {
    let pictures = [];
        for (let i = 0; i < 4; i++) {
            pictures.push(event);
        }

    return (
      <div>
        <Topbar />
        <React.Fragment>
          {/* Hero Start */}
          <section
            className="bg-home"
            style={{ backgroundPosition: "center center" }}
          >
            <div
              className="bg-overlay"
              style={{ backgroundColor: "rgba(10, 10, 10, 0.9)" }}
            ></div>
            <div className="home-center">
              <div className="home-desc-center">
                <div className="container">
                  <Row className="justify-content-center">
                    <Col
                      lg={10}
                      className="text-center"
                      style={{ marginTop: "25px" }}
                    >
                      <div className="title-heading">
                        <h4 className="text-danger mb-3">
                          27th - 29th February, 2020
                        </h4>
                        <h1 className="display-4 text-white font-weight-bold mb-3">
                          All Jacked Up Rodeo BBQ Cook Off
                        </h1>
                        <p className="para-desc mx-auto text-light">
                          Come out and join us at the World's Championship
                          Bar-B-Que Contest
                        </p>
                        <p className="para-desc mx-auto text-light">
                          Houston Livestock Show and Rodeo
                        </p>
                        {/* <Row style={{height: "325px", padding: "auto"}}></Row> */}
                        <Row>
                          <Col md={12} className="text-center">
                            <div id="eventdown">
                              <Countdown
                                date={new Date("2020-02-27T18:00:00.0000z")}
                                renderer={renderer}
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="mt-4 pt-2">
                          <Link to="/contact" className="btn btn-danger">
                            <i className="mdi mdi-ticket"></i> Contact Us
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* <div className="container-fluid">
                <Row>
                  <div className="home-shape-bottom">
                    <div className="text-center bg-white p-4">
                      <h5 className="text-dark mb-0">Next Event : February 2021</h5>
                    </div>
                  </div>
                </Row>
              </div> */}
              </div>
            </div>
          </section>
          {/* Best Event Photos Here */}
          <div
            className="container-fluid"
            style={{textAlign: "center"}}
          >
            <div className="section-title mb-4 pb-2" style={{marginTop: "40px"}}>
                <h4 className="title mb-4"><a href="/photos" className="name text-dark">All Jacked Up Pictures</a></h4>
            </div>
            <Row className="container-grid projects-wrapper">
              
              {true
                ? pictures.map((pic, index) => {
                    return (
                      <Col lg={3} md={6} className="spacing designing">
                        <div className="work-container position-relative d-block overflow-hidden rounded mt-3">
                          <a
                            className="mfp-image d-inline-block"
                            target="_blank"
                            href={event}
                            title=""
                          >
                            <img
                              src={pic}
                              className="img-fluid rounded"
                              alt="All Jacked Up"
                            />
                            <div className="overlay-work"></div>
                          </a>
                        </div>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </div>
          {/* Sponsors */}
          <Sponsors />
        </React.Fragment>
      </div>
    );
  }
}

export default EventHome;
