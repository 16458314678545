import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import EventHome from "./EventHome";
import Contact from "./Contact";
import Photos from './Photos';
import Dovetail from './images/Dovetail.png';

// Import Css
import './Apps.scss';
// import './css/materialdesignicons.min.css';

export default class App extends React.Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={EventHome} />
          <Route path='/photos' component={Photos} />
          <Route path='/contact' component={Contact} />
        </Switch>
      </BrowserRouter>
    );
  }
        
}
      
